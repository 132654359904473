import React, { useContext, useEffect, useState } from 'react';
import styles from './index.module.less';
import Image from 'next/image';
import { GlobalContext, PageType } from '@/pages';
import Drawer from 'components/Drawer';
import classNames from 'classnames';
import ContactUs from 'components/ContactUs';
import { t } from '@lingui/macro';
import { useIsInternational } from '@/hooks/useIsInternational';
// import Link from 'next/link';
import { useRouter } from 'next/router';
import Icon from 'components/Icon';
import Cookies from 'js-cookie';
import { clickBaiduEvent } from '@/libs';

export const LogoPath = require('@/public/images/Logo.png');

const Header: React.FC<any> = () => {
  const { activePage, setActivePage } = useContext(GlobalContext);
  const [isShowContactUsDrawer, setIsShowContactUsDrawer] =
    useState<boolean>(false);
  const router = useRouter();
  useEffect(() => {
    setIsShowContactUsDrawer(false);
  }, [activePage]);

  const { isInternational, lang, isLang } = useIsInternational();

  // 导航宽度
  const NavItemWidth = 120;

  const NavList = [
    { name: t`首页`, show: true, type: PageType.HomePage },
    { name: t`产品介绍`, show: true, type: PageType.Products },
    { name: t`关于我们`, show: true, type: PageType.AboutUs },
    { name: t`加入我们`, show: !isLang('zh-tw'), type: PageType.JoinUs },
    { name: t`新闻动态`, show: !isInternational, type: PageType.NEWS }, // 屏幕滚动 到此为止，国际版暂不展示
    { name: t`联系我们`, show: true, type: PageType.Others },
  ];

  const langMap = {
    zh: '简体中文',
    ['zh-tw']: '繁體中文',
    jp: '日本語',
    kr: '한국어',
    en: 'English',
  };

  const clickNav = (index: number) => {
    if (index === PageType.Others) {
      setIsShowContactUsDrawer(true);
      return;
    }
    if (index === PageType.JoinUs) {
      setActivePage?.(index);
      return;
    }
    setActivePage?.(index);
  };

  const clickLogo = () => {
    setActivePage?.(PageType.HomePage);
  };

  return (
    <header className={styles.mj_header_container}>
      <Image
        onClick={clickLogo}
        className={styles.mj_header_Logo}
        width={106}
        height={50}
        src={LogoPath}
        alt="Manjuu"
      />
      <nav className={styles.mj_header_nav_container}>
        {NavList.map(
          nav =>
            nav.show && (
              <div
                style={{ width: `${NavItemWidth}px` }}
                className={classNames([
                  styles.mj_header_nav_item,
                  nav.type === activePage && styles.mj_header_nav_item_active,
                  nav.type === PageType.Others &&
                    isShowContactUsDrawer &&
                    styles.mj_header_nav_item_contactUsActive,
                ])}
                key={nav.type}
                onClick={e => {
                  if (nav.type === PageType.JoinUs) {
                    clickBaiduEvent(push => {
                      push(['_trackCustomEvent', 'click_joinus', {}]);
                    });
                  }
                  e.stopPropagation();
                  clickNav(nav.type);
                }}>
                <span>{nav.name}</span>
              </div>
            )
        )}
        <div className={styles.mj_header_select}>
          <div
            style={{ width: `${NavItemWidth}px` }}
            className={styles.mj_header_nav_item}>
            <Icon
              style={{ fontSize: '20px', marginRight: '5px' }}
              fontClass="ICON-29"
            />
            <span>{langMap[lang]}</span>
            <div className={styles.colLine} />
          </div>

          <div className={styles.mj_header_select_body}>
            {Object.entries(langMap).map(
              ([k, v]) =>
                k !== lang && (
                  <div
                    onClick={() => {
                      Cookies.set('lang', k, { expires: 30 });
                      router.push(router.pathname, router.pathname, {
                        locale: k,
                      });
                    }}
                    key={k}
                    className={styles.mj_header_select_item}>
                    {v}
                  </div>
                )
            )}
          </div>
        </div>
        <div
          style={{
            left: activePage * NavItemWidth,
            width: `${NavItemWidth}px`,
          }}
          className={styles.mj_header_nav_cursor}
        />
      </nav>
      <Drawer
        width={528}
        offsetTop={84}
        offsetRight={4}
        isShowMask
        maskOffsetTop={84}
        isShowCloseButton={false}
        clickMaskClosable={true}
        visible={isShowContactUsDrawer}
        onClose={setIsShowContactUsDrawer}>
        <ContactUs setIsShowContactUsDrawer={setIsShowContactUsDrawer} />
      </Drawer>
    </header>
  );
};

export default Header;
